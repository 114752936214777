import Api from '@/services/Api';
import axios from 'axios'

export default {
    // Saif
    // getMovieByThirdpartyID(thirdpartyID) {
    //     return Api().post('api/thirdparty/MapMovieDetails/', { ThirdpartyID: thirdpartyID })
    // },
    // addThirdPartyMovies(data) {
    //     console.log("hbashdbshadbhabs", data)
    //     return Api().post('api/dashuser/MapMovies/', data)

    // },
    // getMoviesAccordingCinema(payload) {
    //     // return Api().post('api/dashuser/GetShowReport/', payload)
    // },
    // getFormatData(payload) {
    //     return Api().post('api/dashuser/GetMovieFormatLanguage/', payload)

    // },
    // getGenreList() {
    //     return Api().get('api/dashuser/GetGenreList')
    // }
    getCronJobFileData() {
        return Api().get('api/FileList')
    },
}