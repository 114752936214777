<template>
    <div class="animated fadeIn">
    <div id="snackbar"></div>    
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Cron Job</strong>
          </div>
            <b-form v-on:submit.prevent.self="onSubmit">
                <!-- <b-tabs>
                    <b-tab title="Minutes" active>
                        <br>Minutes
                    </b-tab>
                    <b-tab title="Hourly" >
                        <br>Hourly
                    </b-tab>
                    <b-tab title="Daily">
                        <br>Daily
                    </b-tab>

                    <b-tab title="Weekly" active>
                        <br>Weekly
                    </b-tab>
                    <b-tab title="Monthly" >
                        <br>Monthly
                    </b-tab>
                    <b-tab title="Yealy">
                        <br>Yealy
                    </b-tab>
                    
                </b-tabs> -->
                <b-row>
                    <b-col sm="6">
                        <b-form-group>
                            <label for="min_seats">Cron Name <span class="text-danger">*</span></label>
                            <b-form-input type="text" v-model="cronJobData.cronName" id="minSeats" placeholder="Cron Name"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-form-group>
                            <label for="cronJobPathList">Select Cron Path</label>                            
                            <b-form-select required v-model="cronJobData.selectedCronJobPath" id="cronJobPathList"
                                :plain="true"
                                :options=cronJobData.cronJobPathList
                                >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group>
                            <label for="cronJobPathList">Select Cron Minutes</label>                            
                            <b-form-select required v-model="cronJobData.selectedMinutes" id="cronJobPathList"
                                :plain="true"
                                :options=cronJobData.minutesList
                                >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <b-form-group>
                            <label for="cronJobPathList">Select Cron Hours</label>                            
                            <b-form-select required v-model="cronJobData.selectedHours" id="cronJobPathList"
                                :plain="true"
                                :options=cronJobData.hoursList
                                >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group>
                            <label for="cronJobPathList">Select Cron Months</label>                            
                            <b-form-select required v-model="cronJobData.selectedMonths" id="cronJobPathList"
                                :plain="true"
                                :options=cronJobData.monthsList
                                >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group>
                            <label for="cronJobPathList">Select Cron Day</label>                            
                            <b-form-select required v-model="cronJobData.selectedDays" id="cronJobPathList"
                                :plain="true"
                                :options=cronJobData.daysList
                                >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- <b-col sm="3">
                        <b-form-group>
                            <label for="cronJobPathList">Select Cron Hours</label>                            
                            <b-form-select required v-model="cronJobData.selectedHours" id="cronJobPathList"
                                :plain="true"
                                :options=cronJobData.HoursList
                                >
                            </b-form-select>
                        </b-form-group>
                    </b-col> -->
                </b-row>
             

            <b-row class="mt-3">
                <b-col sm="6">
                    <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
                </b-col>

                <b-col sm="6">
                    <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset </b-button>
                </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Crons
        <div class="card-header-actions">
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="cronData" :options="options" :theme="theme" id="dataTable">
        
        </v-client-table>
      </b-card-body>
    </b-card>
      </b-col>
    </b-row>
  </div>    
</template>
<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import CronJobService from "@/services/CronJobService";
import { BASEURL } from "../../helper/constant.js";

export default {
  name: "Countries",
  components: {
    cSwitch
  },
  data() {
    return {
      cronJobData: {
        cronName: "",
        cronJobPathList: [],
        selectedCronJobPath: "0",
        selectedMinutes: 0,
        minutesList: [],
        hoursList: [],
        selectedHours: 0,
        monthsList: [],
        selectedMonths: 1,
        selectedDays: 0,
        daysList: []
      },

      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns: ["CronName", "CronPath", "CronTime", "Action"],
      cronData: [],
      options: {
        headings: {
          CronName: "Cron Name",
          CronPath: "Cron Path",
          CronTime: "Cron Time",
          Action: "Action"
        },
        sortable: ["CronName", "CronPath", "CronTime"],
        filterable: ["CronName", "CronPath", "CronTime"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  beforeMount() {
    this.getCronJobList();
    this.getCronJobFile();
    for (let i = 0; i < 60; i++) {
      this.cronJobData.minutesList.push(i);
      //   console.log("LLLLLL", i > 23, i < 23);
      if (i < 24) {
        this.cronJobData.hoursList.push(i);
      }
      if (i !== 0 && i < 13) {
        this.cronJobData.monthsList.push(i);
      }
      if (i < 7) {
        this.cronJobData.daysList.push(i);
      }
    }
  },
  mounted() {},
  methods: {
    resetForm() {
      //   this.$refs.fileinput.reset();
    },
    getCronJobFile: function() {
      CronJobService.getCronJobFileData()
        .then(response => {
          console.log("Check response ", response);
          this.cronJobData.cronJobPathList.push({
            text: "Select Cron Path",
            value: "0"
          });
          if (response.data.Status) {
            if (response.data.Data.length) {
              response.data.Data.map(x => {
                this.cronJobData.cronJobPathList.push({
                  text: x,
                  value: x
                });
              });
            }
          } else {
            this.showMessageSnackbar(response.data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(error);
        });
    },
    getCronJobList: function() {
      this.cronData = [];
    },
    showMessageSnackbar: function(Message) {
      let x = document.getElementById("snackbar");
      // Add the "show" class to DIV
      x.className = "show";
      x.innerHTML = Message;
      // After 3 seconds, remove the show class from DIV
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
    async onSubmit() {
      console.log("here is the Result ", this.cronJobData);
      //   let res = await MasterService.addCountry(this.country);
      //   if (res.data) {
      //     var x = document.getElementById("snackbar");
      //     if (res.data.Status == true && res.data.Flag == 1) {
      //       var previewDiv = document.getElementById("preview");
      //       var previewImg = "";
      //       var previewImg = document.createElement("img");
      //       while (previewDiv.firstChild) {
      //         previewDiv.removeChild(previewDiv.firstChild);
      //       }
      //       this.$refs.fileinput.reset();
      //       (this.country = {
      //         CountryID: null,
      //         CountryName: "",
      //         Time_Offset: "",
      //         Currency_ISOCode: "",
      //         ISOCode: "",
      //         CountryIsActive: true,
      //         CountryFlag: ""
      //       }),
      //         (this.countries = []);
      //     }
      this.selectedCronJobPath("Thanks");
    },
    async editCountry(countryID) {},
    async changeStatus(ID) {}
  }
};
</script>